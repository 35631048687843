import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import router from "./router";
import http from "./http";
import VueAwesomeSwiper from "vue-awesome-swiper";
import common from "./common/function";
import "swiper/swiper-bundle.css";
import store from "./store";
import { Lazyload } from "vant";
import "video.js/dist/video-js.css";
import { Locale } from "vant";
import enUS from "vant/es/locale/lang/en-US";
Locale.use('en-US', enUS);
import "@/assets/css/base.less";
import i18n from "./lang/il8n";

Vue.prototype.$http = http;
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(Vant);
Vue.use(Lazyload, {
  lazyComponent: true,
});
const ROOTVUE = new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");

export default ROOTVUE;
