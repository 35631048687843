<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    :placeholder="true"
    :fixed="true"
    inactive-color="#979799">
    <van-tabbar-item
      v-for="(k, key) in item"
      replace
      :to="k.router"
      :key="key">
      <span>{{ k.title }}</span>
      <template #icon="props">
        <img
          :src="props.active ? k.icon.active : k.icon.noactive"
          :alt="k.title"
          v-show="key !== 2" />
        <img
          :src="props.active ? k.icon.active : k.icon.noactive"
          :alt="k.title"
          v-show="key === 2" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        active: 0,
        item: [
          {
            router: "/Home",
            title: this.$t(`Page['首页']`),
            icon: {
              active: "/img/footer/首页选中.png",
              noactive: "/img/footer/首页未选中.png",
            },
          },
          {
            router: "/Game",
            title: this.$t(`Page['任务']`),
            icon: {
              active: "/img/footer/班次中心选中.png",
              noactive: "/img/footer/班次中心未选中.png",
            },
          },
          {
            router: "/Mine",
            title: this.$t(`Page['我的']`),
            icon: {
              active: "/img/footer/我的选中.png",
              noactive: "/img/footer/我的未选中.png",
            },
          },
        ],
      };
    },
    methods: {},
    watch: {
      $route(to) {
        if (to.name == "home") {
          this.active = 0;
          this.show = true;
        } else if (to.name == "game") {
          this.active = 1;
          this.show = true;
        } else if (to.name == "mine") {
          this.active = 2;
          this.show = true;
        } else {
          this.show = false;
        }
      },
    },
    created() {
      if (this.$route.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (this.$route.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (this.$route.name == "mine") {
        this.active = 2;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  };
</script>

<style lang="less"></style>
