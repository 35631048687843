<template>
  <div class="page_game">
    <van-nav-bar
      border
      fixed
      placeholder>
      <template #title>
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="downValueId"
            :options="gameOptionDown"
            @change="gameChage" />
        </van-dropdown-menu>
      </template>
    </van-nav-bar>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh">
      <div class="game_header">
        <div class="game_item">
          <div class="game_title_box">
            <div class="game_title">{{ lotteryInfo.nextExpect }}</div>
            <div class="game_text">{{ $t(`Page['数据匹配截止']`) }}</div>
          </div>
          <div class="result_box">
            <van-count-down
              v-if="time > 0"
              :time="time"
              format="HH:mm:ss"
              @finish="runLottery()">
            </van-count-down>
            <div
              v-if="showRunLottery"
              class="lottery_text"
              >{{ `${$t(`Page['启动中']`)}...` }}</div
            >
          </div>
        </div>
        <div class="line"></div>
        <div class="game_item">
          <div
            class="game_title_box"
            @click="showLotteryHistory = true">
            <div class="game_title">{{ lotteryInfo.nowExpect }}</div>
            <div class="game_text">{{ $t(`Page['匹配结果']`) }}</div>
            <van-icon name="arrow-down" />
          </div>
          <div class="result_box">
            <div class="result_item">{{ lotteryInfo.sizeResult }}</div>
            <div class="result_item">{{ lotteryInfo.oddEvenResult }}</div>
          </div>
        </div>
      </div>
      <div class="bottom_pour_content">
        <div class="bottom_pour_list">
          <div
            class="bottom_pour_item"
            @click="triggerBottomPour(key)"
            v-for="(item, key) in bottomPourAnalysisList"
            :class="targetBottomPour.indexOf(key) !== -1 ? 'active' : ''"
            :key="key">
            <div class="text">
              {{ item.analysisText }}
            </div>
            <!-- <div class="num">
              {{ item.proportion }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="cooperation_box">
        <div class="cooperation_title">
          <van-divider dashed>{{ $t(`Page['合作伙伴']`) }}</van-divider>
        </div>
        <div class="honeycomb">
          <div
            class="honeycomb_item"
            v-for="(item, key) in cooperationList"
            :key="key">
            <div class="honeycomb_cell">
              <img
                :src="item.url"
                class="honeycomb_img" />
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <van-submit-bar
      :loading="bottomPourLoading"
      :safe-area-inset-bottom="false"
      :price="totalPrice"
      :button-text="$t(`Page['提交']`)"
      currency=""
      @submit="submitOrder">
      <van-button
        type="danger"
        size="mini"
        @click="targetBottomPour = []"
        >{{ $t(`Page['清除']`) }}</van-button
      >
      <div class="total_price">
        <div class="text">{{ `${$t(`Page['共']`)}：` }}</div>
        <div class="price">{{ targetBottomPour.length }}</div>
        <div class="text">{{ `${$t(`Page['注']`)}` }}</div>
      </div>
      <template #tip>
        <van-field
          clickable
          v-model="price"
          type="number"
          clearable
          :label="`${$t(`Page['订单金额']`)}：`"
          :placeholder="$t(`Page['请输入金额']`)">
          <template #button>
            <div
              class="price_box"
              @click="showMoney = !showMoney">
              <div class="text">{{ `${$t(`Page['余额']`)}：` }}</div>
              <div class="price">{{
                showMoney ? userInfo.money : "******"
              }}</div>
              <van-icon name="closed-eye" />
            </div>
          </template>
        </van-field>
      </template>
    </van-submit-bar>
    <van-popup
      v-model="showLotteryHistory"
      position="bottom"
      closeable
      round
      @opened="getLotteryList"
      :style="{ height: '70vh' }">
      <div class="lottery_history_box">
        <div class="history_title">{{ `${$t(`Page['开奖记录']`)}` }}</div>
        <div class="history_content">
          <van-pull-refresh
            v-model="historyRefresh"
            @refresh="getLotteryList">
            <van-cell
              v-for="(item, key) in lotteryHistoryList"
              :key="key"
              :title="item.expectText">
              <div class="result_box">
                <div class="result_item">{{ item.sizeResult }}</div>
                <div class="result_item">{{ item.oddEvenResult }}</div>
              </div>
            </van-cell>
          </van-pull-refresh>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        gameitem: [],
        isLoading: false,
        downValueId: 0,
        userInfo: {},
        lottery: {},
        time: 0,
        showRunLottery: false,
        showLotteryHistory: false,
        historyRefresh: false,
        lotteryList: [],
        bottomPourList: [],
        targetBottomPour: [],
        bottomPourLoading: false,
        price: "",
        showMoney: true,
      };
    },
    computed: {
      cooperationList() {
        return [
          {
            url: "/img/lottery/ico/chanel.png",
            name: "CHANEL",
          },
          {
            url: "/img/lottery/ico/FENDI.png",
            name: "FENDI",
          },
          {
            url: "/img/lottery/ico/GUCCI.png",
            name: "GUCCI",
          },
          {
            url: "/img/lottery/ico/HERMES.png",
            name: "HERMES",
          },
          {
            url: "/img/lottery/ico/LV.png",
            name: "LV",
          },
          {
            url: "/img/lottery/ico/VERSACE.png",
            name: "VERSACE",
          },
          {
            url: "/img/lottery/ico/LINING.png",
            name: "LINING",
          },
          {
            url: "/img/lottery/ico/ANTA.png",
            name: "ANTA",
          },
          {
            url: "/img/lottery/ico/DONGFANG.png",
            name: "DONGFANG",
          },
          {
            url: "/img/lottery/ico/QUANJI.png",
            name: "QUANJI",
          },
          {
            url: "/img/lottery/ico/HANTING.png",
            name: "HANTING",
          },
          {
            url: "/img/lottery/ico/ZTE.png",
            name: "ZTE",
          },
          {
            url: "/img/lottery/ico/BELLE.png",
            name: "BELLE",
          },
          {
            url: "/img/lottery/ico/QPL.png",
            name: "QPL",
          },
          {
            url: "/img/lottery/ico/ADIDAS.png",
            name: "ADIDAS",
          },
          {
            url: "/img/lottery/ico/OMEGA.png",
            name: "OMEGA",
          },
          {
            url: "/img/lottery/ico/JLFD.png",
            name: "JLFD",
          },
          {
            url: "/img/lottery/ico/HILTON.png",
            name: "HILTON",
          },
        ];
      },
      totalPrice() {
        let { price, targetBottomPour } = this;
        return Number(price) * targetBottomPour.length * 100;
      },
      bottomPourAnalysisList() {
        let { bottomPourList } = this;
        return bottomPourList.map((item) => {
          return {
            ...item,
            analysisText: this.analysisBottomPour(item.name),
          };
        });
      },
      gameOptionDown() {
        let { gameitem } = this;
        return gameitem.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      },
      lotteryParams() {
        let { downValueId, gameitem } = this;
        let targetGame = gameitem.filter((item) => {
          return item.id === downValueId;
        });
        return targetGame.length ? targetGame[0] : {};
      },
      lotteryHistoryList() {
        let { lotteryList } = this;
        return lotteryList.map((item) => {
          return {
            ...item,
            expectText: `${item.expect} ${this.$t(`Page['期']`)}`,
            size: item.opencode
              ? this.setRunLotteryResult(item.opencode).size
              : 0,
            sizeResult: item.opencode
              ? this.setRunLotteryResult(item.opencode).sizeResult
              : "---",
            oddEvenResult: item.opencode
              ? this.setRunLotteryResult(item.opencode).oddEvenResult
              : "---",
          };
        });
      },
      lotteryInfo() {
        let { lottery } = this;
        if (Object.keys(lottery).length) {
          return {
            ...lottery,
            nextExpect: `${lottery.next_expect.substr(8)} ${this.$t(
              `Page['期']`,
            )}`,
            nowExpect: `${lottery.now_expect.substr(8)} ${this.$t(
              `Page['期']`,
            )}`,
            size: lottery.opencode
              ? this.setRunLotteryResult(lottery.opencode).size
              : 0,
            sizeResult: lottery.opencode
              ? this.setRunLotteryResult(lottery.opencode).sizeResult
              : "---",
            oddEvenResult: lottery.opencode
              ? this.setRunLotteryResult(lottery.opencode).oddEvenResult
              : "---",
          };
        } else {
          return {
            nextExpect: "---",
            nowExpect: "---",
            size: 0,
            sizeResult: "---",
            oddEvenResult: "---",
          };
        }
      },
    },
    methods: {
      submitOrder() {
        let {
          price,
          userInfo,
          totalPrice,
          targetBottomPour,
          bottomPourAnalysisList,
          lotteryInfo,
        } = this;
        if (!price) {
          this.$toast(this.$t(`Page['请输入订单金额']`));
          return false;
        }
        if (!targetBottomPour.length) {
          this.$toast(this.$t(`Page['请下单']`));
          return false;
        }
        if (userInfo.money - totalPrice / 100 < 0) {
          this.$toast(this.$t(`Page['余额不足，请联系客服充值']`));
          return false;
        }
        let resNum = 0;
        bottomPourAnalysisList.forEach((item, key) => {
          targetBottomPour.forEach((pour) => {
            if (key === pour) {
              this.$http({
                method: "post",
                data: {
                  item: item.type,
                  money: price,
                  lid: lotteryInfo.id,
                  mid: userInfo.id,
                  expect: lotteryInfo.now_expect,
                },
                url: "game_place_order",
              }).then((res) => {
                let { code, msg } = res;
                if (code === 200) {
                  if (resNum === targetBottomPour.length - 1) {
                    this.targetBottomPour = [];
                    this.getUserInfo();
                  }
                }
                if (!resNum) {
                  this.$toast(msg);
                }
                resNum++;
              });
            }
          });
        });
      },
      triggerBottomPour(key) {
        let { targetBottomPour } = this;
        if (targetBottomPour.indexOf(key) !== -1) {
          this.targetBottomPour.splice(targetBottomPour.indexOf(key), 1);
        } else {
          this.targetBottomPour.push(key);
        }
      },
      gameChage() {
        this.getUserInfo();
        this.getLotteryInfo();
      },
      analysisBottomPour(name) {
        let { lotteryParams } = this;
        let key = lotteryParams.key;
        let res = "";
        switch (name) {
          case "大":
            switch (key) {
              case "game1":
                res = "A";
                break;
              case "game2":
                res = "A";
                break;
              case "game3":
                res = "A";
                break;
              case "game4":
                res = "A";
                break;
            }
            break;
          case "小":
            switch (key) {
              case "game1":
                res = "B";
                break;
              case "game2":
                res = "B";
                break;
              case "game3":
                res = "B";
                break;
              case "game4":
                res = "B";
                break;
            }
            break;
          case "单":
            res = "C";
            break;
          case "双":
            res = "D";
            break;
          default:
            res = "name";
            break;
        }
        return res;
      },
      setRunLotteryResult(opencode) {
        let size = opencode.reduce((a, b) => {
          return a + b;
        });
        let { lotteryParams } = this;
        let key = lotteryParams.key;
        let sizeResult = "";
        let oddEvenResult = "";
        if (size >= 11 && size <= 18) {
          switch (key) {
            case "game1":
              sizeResult = "A";
              break;
            case "game2":
              sizeResult = "A";
              break;
            case "game3":
              sizeResult = "A";
              break;
            case "game4":
              sizeResult = "A";
              break;
          }
        } else if (size >= 3 && size <= 10) {
          switch (key) {
            case "game1":
              sizeResult = "B";
              break;
            case "game2":
              sizeResult = "B";
              break;
            case "game3":
              sizeResult = "B";
              break;
            case "game4":
              sizeResult = "B";
              break;
          }
        }
        if (size % 2 === 0) {
          oddEvenResult = "D";
        } else {
          oddEvenResult = "C";
        }
        return {
          size,
          sizeResult,
          oddEvenResult,
        };
      },
      onRefresh() {
        this.targetBottomPour = [];
        this.price = "";
        setTimeout(() => {
          this.getUserInfo();
          this.getLotteryInfo();
          this.getLotteryPeilv();
          this.isLoading = false;
          this.$toast(this.$t(`Page['刷新成功']`));
        }, 500);
      },
      runLottery() {
        this.time = 0;
        this.showRunLottery = true;
        if (!localStorage.getItem("token")) {
          this.$router.push({ path: "/Login" });
        } else {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
          }, 800);
        }
      },
      getUserInfo() {
        this.$http({
          method: "get",
          url: "user_info",
        }).then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
      getLotteryPeilv() {
        let { lotteryParams } = this;
        this.$http({
          method: "get",
          data: { id: lotteryParams.id },
          url: "lottery_get_peilv",
        }).then((res) => {
          let { code, data, msg } = res;
          if (code === 200) {
            this.bottomPourList = data;
          } else if (res.code === 401) {
            this.$toast(msg);
          }
        });
      },
      getLotteryList() {
        this.historyRefresh = true;
        let { lotteryParams } = this;
        this.$http({
          method: "get",
          data: { key: lotteryParams.key },
          url: "lottery_get_one_list",
        }).then((res) => {
          let { code, data, msg } = res;
          if (code === 200) {
            this.lotteryList = data;
          } else if (code === 401) {
            this.$toast(msg);
          }
          this.historyRefresh = false;
        });
      },
      getGameItem() {
        this.$http({
          method: "get",
          url: "lottery_hot",
        }).then((res) => {
          this.gameitem = res.data;
          let { downValueId } = this;
          if (!downValueId) {
            this.downValueId = this.gameitem[0].id;
          }
          this.getLotteryInfo();
          this.getLotteryPeilv();
        });
      },
      getLotteryInfo() {
        let { lotteryParams } = this;
        this.$http({
          method: "get",
          data: { key: lotteryParams.key },
          url: "lottery_get_info",
        }).then((res) => {
          let { code, data, msg } = res;
          if (code === 200) {
            if (
              parseFloat(this.userInfo.money) < parseFloat(res.data.condition)
            ) {
              this.$toast(this.$t(`Page['请联系管理员领取该任务']`));
              this.$router.push({ path: "/Home" });
              return false;
            }
            let { lotteryInfo } = this;
            if (lotteryInfo.next_expect === data.now_expect) {
              this.showRunLottery = false;
              this.$toast(
                `${this.$t(`Page['启动成功']`)}\n${this.$t(`Page['期号']`)}：${
                  data.now_expect
                }`,
              );
              if (this.showLotteryHistory) {
                this.getLotteryList();
              }
            }
            this.lottery = data;
            this.time = data.second * 1000;
          } else if (code === 401) {
            this.$toast(msg);
          }
        });
      },
    },
    mounted() {
      let queryId = this.$route.query.id;
      if (queryId) {
        this.downValueId = Number(queryId);
      }
      this.getUserInfo();
      this.getGameItem(); //获取首页游戏列表
    },
  };
</script>

<style lang="less">
  @font-face {
    font-family: electronicFont;
    src: url("../../assets/font/DS-DIGIT-4.ttf");
  }
  .page_game {
    min-height: calc(100vh - 50px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 50px - env(safe-area-inset-bottom));
    background-color: #f5f5f5;
    .van-nav-bar {
      background: linear-gradient(220.55deg, #3793ff 0%, #0017e4 100%);
      .van-nav-bar__title {
        color: #fff;
        overflow: initial;
        .van-dropdown-menu__bar {
          background-color: transparent;
          box-shadow: none;
        }
        .van-dropdown-menu__title {
          color: #fff;
        }
      }
    }
    .van-submit-bar {
      bottom: calc(50px + constant(safe-area-inset-bottom));
      bottom: calc(50px + env(safe-area-inset-bottom));
      background-color: #343434;
      .van-submit-bar__tip {
        background-color: transparent;
        padding: 0px;
      }
      .price_box {
        display: flex;
        align-items: center;
        .text {
          color: #333;
          padding-right: 2px;
        }
        .price {
          color: #ee0a24;
          padding-right: 6px;
        }
      }
      .van-submit-bar__text {
        color: #fff;
      }
      .total_price {
        padding-left: 4px;
        display: flex;
        align-items: center;
        .text {
          color: #fff;
          font-size: 10px;
        }
        .price {
          color: #ee0a24;
          padding-right: 6px;
          font-size: 12px;
        }
      }
    }
    .van-pull-refresh {
      min-height: calc(100vh - 96px - constant(safe-area-inset-bottom));
      min-height: calc(100vh - 96px - env(safe-area-inset-bottom));
      .game_header {
        background-color: #fff;
        padding: 12px 0;
        display: flex;
        align-items: center;
        .game_item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .game_title_box {
            display: flex;
            align-items: center;
            .game_title {
              font-size: 12px;
              color: #7e5678;
              padding-right: 6px;
              font-weight: bold;
            }
            .game_text {
              font-size: 12px;
              color: #333;
            }
          }
          .result_box {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .van-count-down {
              font-size: 30px;
              color: #0e94ea;
              font-family: electronicFont;
              font-weight: bold;
            }
            .lottery_text {
              color: #333;
              font-size: 20px;
            }
            .result_item {
              color: #ee0a24;
              font-size: 20px;
              padding: 0 6px;
            }
          }
        }
        .line {
          height: 50px;
          width: 1px;
          background-color: #d3d3d3;
        }
      }
      .bottom_pour_content {
        padding: 16px;
        .bottom_pour_list {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 12px;
          .bottom_pour_item {
            background-color: #fff;
            border-radius: 4px;
            padding: 8px;
            box-shadow: 0 2px 12px rgb(100 101 102 / 12%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .text {
              color: #ee0a24;
              font-size: 16px;
              font-weight: 500;
              padding-bottom: 4px;
            }
            .num {
              color: #ee0a24;
              font-size: 14px;
            }
          }
          .bottom_pour_item.active {
            background-color: #ee0a24;
            .text {
              color: #fff;
            }
            .num {
              color: #fff;
            }
          }
        }
      }
      .cooperation_box {
        padding-bottom: 110px;
        .cooperation_title {
          padding: 0 12px;
          .van-divider {
            font-size: 16px;
            color: #333;
            font-weight: 500;
            border-color: #999;
          }
        }
        .honeycomb {
          overflow: hidden;
          width: 90%;
          margin: 0 auto;
          padding: 0.866% 0;
          font-family: "Raleway", sans-serif;
          .honeycomb_item {
            position: relative;
            list-style-type: none;
            float: left;
            overflow: hidden;
            visibility: hidden;
            outline: 1px solid transparent;
            transform: rotate(-60deg) skewY(30deg) translatez(-1px);
            width: 19.2%;
            padding-bottom: 22.17%;
            * {
              position: absolute;
              visibility: visible;
            }
            .honeycomb_cell {
              display: block;
              width: 100%;
              height: 100%;
              text-align: center;
              color: #fff;
              overflow: hidden;
              transform: skewY(-30deg) rotate(60deg);
              .honeycomb_img {
                left: -100%;
                right: -100%;
                width: auto;
                height: 100%;
                margin: 0 auto;
              }
            }
          }
          .honeycomb_item:nth-child(9n + 6),
          .honeycomb_item:nth-child(9n + 7),
          .honeycomb_item:nth-child(9n + 8),
          .honeycomb_item:nth-child(9n + 9) {
            margin-top: -4.676%;
            margin-bottom: -4.676%;
            transform: translateX(50%) rotate(-60deg) skewY(30deg);
          }
          .honeycomb_item:nth-child(9n + 6):last-child,
          .honeycomb_item:nth-child(9n + 7):last-child,
          .honeycomb_item:nth-child(9n + 8):last-child,
          .honeycomb_item:nth-child(9n + 9):last-child {
            margin-bottom: 0;
          }
          .hehoneycomb_itemx:nth-child(9n + 6) {
            margin-left: 0.5%;
            clear: left;
          }
          .honeycomb_item:nth-child(9n + 10) {
            clear: left;
          }
          .honeycomb_item:nth-child(9n + 2),
          .honeycomb_item:nth-child(9n + 7) {
            margin-left: 1%;
            margin-right: 1%;
          }
          .honeycomb_item:nth-child(9n + 3),
          .honeycomb_item:nth-child(9n + 4),
          .honeycomb_item:nth-child(9n + 8) {
            margin-right: 1%;
          }
        }
      }
    }
    .lottery_history_box {
      padding: 1px;
      .history_title {
        text-align: center;
        padding-bottom: 12px;
        color: #333;
        font-size: 24px;
      }
      .result_box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .result_item {
          color: #ee0a24;
          font-size: 20px;
          padding: 0 6px;
        }
      }
    }
  }
</style>
