<template>
  <div class="container page">
    <van-nav-bar
      :title="$t(`Page['任务记录']`)"
      class="nav-bar">
      <template #left>
        <van-icon
          name="arrow-left"
          color="#fff"
          @click="back()" />
      </template>
    </van-nav-bar>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh">
      <van-empty
        v-if="list.length === 0"
        :description="$t(`Page['数据为空']`)" />
      <div
        v-else
        class="item_list"
        v-for="(v, key) in list"
        :key="key">
        <div class="lottery_info">
          <van-image
            class="cover"
            :src="v.ico">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="period-number">{{ v.expect }}</span>
          <span class="period-number">{{ v.name }}</span>
        </div>
        <div class="recent">
          <div class="kuaisan-ball left">
            <span class="res-des middle">{{
              setRunLotteryResult(v).sizeResult
            }}</span>
            <span class="res-des middle">{{
              setRunLotteryResult(v).oddEvenResult
            }}</span>
          </div>
        </div>
        <div class="topInfo">
          <span
            v-if="v.status === 1"
            style="color: #07c160"
            >{{ v.status_text }}</span
          >
          <span v-else>{{ v.status_text }}</span>
          <span>{{ `${$t(`Page['下单成功']`)}` }}：{{ v.money }}</span>
        </div>
        <div class="time">
          <span>{{ `${$t(`Page['下单时间']`)}` }}：{{ v.create_time }}</span>
        </div>
        <div class="time">
          <span>{{ `${$t(`Page['结算时间']`)}` }}：{{ v.update_time }}</span>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        list: [],
      };
    },
    methods: {
      setRunLotteryResult(item) {
        let { opencode, status, name } = item;
        let size = 0;
        let sizeResult = "---";
        let oddEvenResult = "---";
        if (status) {
          size = opencode.reduce((a, b) => {
            return a + b;
          });
          if (size >= 11 && size <= 18) {
            switch (name) {
              case "Amazon":
                sizeResult = "A";
                break;
              case "Rakuten":
                sizeResult = "A";
                break;
              case "YaHoo":
                sizeResult = "A";
                break;
              case "Mercari":
                sizeResult = "A";
                break;
            }
          } else if (size >= 3 && size <= 10) {
            switch (name) {
              case "Amazon":
                sizeResult = "B";
                break;
              case "Rakuten":
                sizeResult = "B";
                break;
              case "YaHoo":
                sizeResult = "B";
                break;
              case "Mercari":
                sizeResult = "B";
                break;
            }
          }
          if (size % 2 === 0) {
            oddEvenResult = "D";
          } else {
            oddEvenResult = "C";
          }
        }
        return {
          size,
          sizeResult,
          oddEvenResult,
        };
      },
      back() {
        return window.history.back();
      },
      onRefresh() {
        setTimeout(() => {
          this.$toast(this.$t(`Page['刷新成功']`));
          this.isLoading = false;
        }, 500);
      },
      getUserGameList() {
        this.$http({
          method: "get",
          url: "user_get_game_list",
        }).then((res) => {
          if (res.code === 200) {
            console.log(res.data);
            this.list = res.data;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      },
    },
    created() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.getUserGameList();
      }
    },
  };
</script>

<style lang="less" scoped>
  ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000000;
    font-size: 35px;
  }

  ::v-deep .van-loading__text {
    color: #000000;
    font-size: 35px;
  }
  .container .main {
    position: relative;
    overflow: auto;
    background-color: #f2f2f5;
    height: 100%;
    padding: 0 10px;
  }
  .item_list {
    padding: 15px 15px;
    margin: 30px 10px;
    background: #fff;
    border-radius: 10px;
    line-height: 60px;
  }

  .item_list .topInfo span {
    flex: 1;
    font-size: 35px;
    font-weight: 700;
    color: #ff253f;
  }
  .item_list .time span {
    flex: 1;
    font-size: 25px;
    font-weight: 500;
    color: #000;
  }

  .item_list .topInfo span:last-child {
    float: right;
  }
  .item_list .desc span {
    font-size: 25px;
    font-weight: 700;
    color: #9b9b9b;
  }
  .item_list .cover {
    width: 60px;
    height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .item_list .period-number {
    margin-left: 50px;
    margin-right: 10px;
    height: 50px;
    line-height: 60px;
    font-size: 35px;
    font-weight: 700;
    color: #000;
  }
  .item_list .lottery_info {
    display: flex;
  }
  .recent {
    display: flex;
    align-items: center;
    height: 100px;
  }
  .kuaisan-ball .left {
    justify-content: flex-start;
  }
  .kuaisan-ball {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .kuaisan-ball .res-img {
    width: 70px;
    height: 70px;
    margin-right: 30px;
  }
  .kuaisan-ball .res-des {
    font-weight: 700;
    text-align: center;
    color: #ee0a24;
  }
  .kuaisan-ball .res-des.middle {
    width: 20%;
    font-size: 35px;
  }
</style>
